export default {

  name: 'User',

  user: {
    active: true,
    admin: false,
    beta: false,
    allowedFacilities: [],
    customerNo: '',
    email: '',
    facilityAdmin: false,
    firstName: '',
    id: '',
    loginTime: null,
    invalidLoginAttempts: '',
    lastLoginTime: null,
    lastName: '',
    locked: false,
    initialpw: '',
    manager: false,
    parent: false,
    refreshTokenUsageCount: 0,
    roles: [],
    rolesProfile: '',
    sage: false,
    username: '',
  }
}
