<template>
  <div v-if="user" class="grid-x grid-padding-x">
      <div class="cell large-6 medium-6 small-12">
        <md-field :class="getValidationClass('firstName')">
          <label>Vorname</label>
          <md-input name="first-name" id="first-name" autocomplete="off" v-model="user.firstName"
                    :disabled="sending"/>
          <span class="md-error" v-if="!$v.user.firstName.required">Vorname ist notwendig</span>
          <span class="md-error" v-else-if="!$v.user.firstName.minlength">Ungültiger Vorname</span>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-12">
        <md-field :class="getValidationClass('lastName')">
          <label>Nachname</label>
          <md-input name="last-name" id="last-name" autocomplete="off" v-model="user.lastName"
                    :disabled="sending"/>
          <span class="md-error" v-if="!$v.user.lastName.required">Nachname ist notwendig</span>
          <span class="md-error" v-else-if="!$v.user.lastName.minlength">Ungültiger Nachname</span>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-12">
        <md-field :class="getValidationClass('email')">
          <label>Email</label>
          <md-input type="email" name="email" id="email" autocomplete="off" v-model="user.email"
                    :disabled="sending || (mode === 'update' && !isSage)"/>
          <span class="md-error" v-if="!$v.user.email.required">Email-Adresse ist notwendig</span>
          <span class="md-error" v-else-if="!$v.user.email.email">Ungültige Email-Adresse</span>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-12">
        <md-field :class="getValidationClass('rolesProfile')">
          <label>Benutzer-Profil</label>
          <md-select v-model="user.rolesProfile" autocomplete="off" id="user-profiles"
                     :disabled="sending || (mode === 'update' && !(isSage || isAdmin))">
            <md-option v-for="profile of availableProfiles" :value="profile" :key="profile">{{labels.rolesprofile[profile]}}</md-option>
          </md-select>
          <span class="md-error" v-if="!$v.user.rolesProfile.required">Benutzer-Profil ist notwendig</span>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-12">
        <md-field v-if="mode !== 'update'" :class="getValidationClass('initialpw')">
          <div class="grid-x" style="width: 100%">
            <div class="cell small-12 medium-12 large-4 text-left">
              <label>Passwort
                <span v-if="passwordScore !== null && passwordScore < 3" style="color: orange; font-size: 12px;"><br>Passwort zu schwach</span>
              </label>
            </div>
            <div class="cell small-12 medium-12 large-8 text-right">
              <password id="initial-pw" v-model="user.initialpw" :toggle="true" :secureLength="webAppSettings.pwdSecureLength" @score="showPasswordScore" style="max-width: 100%;" :disabled="sending"/>
            </div>
          </div>
          <span class="md-error" v-if="invalidPassword && user.initialpw.length === 0">Passwort ist notwendig</span>
          <span class="md-error" v-if="!$v.user.initialpw.password && mode !== 'update' && user.initialpw.length > 0">Passwort muss min. {{webAppSettings.pwdSecureLength}} Zeichen lang sein</span>
        </md-field>
        <md-field v-else :class="getValidationClass('initialpw')">
          <div class="grid-x" style="width: 100%">
            <div class="cell small-12 medium-12 large-4 text-left">
              <label>Neues Passwort
                <span v-if="passwordNewScore !== null && passwordNewScore < 3" style="color: orange; font-size: 12px;"><br>Passwort zu schwach</span>
              </label>
              <md-tooltip md-direction="top">Hier kann optional ein neues Passwort angeben werden.</md-tooltip>
            </div>
            <div class="cell small-12 medium-12 large-8 text-right">
              <password id="change-pw" v-model="user.initialpw" :toggle="true" :secureLength="webAppSettings.pwdSecureLength" @score="showpasswordNewScore" style="max-width: 100%;" :disabled="sending"/>
            </div>
          </div>
          <span class="md-error" v-if="!$v.user.initialpw.password && mode === 'update' && user.initialpw.length > 0">Passwort muss min. {{webAppSettings.pwdSecureLength}} Zeichen lang sein</span>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-12">
        <md-field :class="getValidationClass('customerNo')">
          <label>Kunden-Nummer</label>
          <md-select v-if="isSage" v-model="user.customerNo" autocomplete="off"
                     :disabled="sending" @md-opened="onOpenOrganizationSelector">
            <md-option v-for="customerNo of availableCustomerNo" :value="customerNo" :key="customerNo">
              {{availableCustomerNos[customerNo]}}
            </md-option>
          </md-select>
          <md-input v-if="!isSage" name="customer-no" id="customer-no" autocomplete="off"
                    v-model="user.customerNo"
                    :disabled="true"/>
          <span class="md-error" v-if="!$v.user.customerNo.required">Kunden-Nummer ist notwendig</span>
        </md-field>
      </div>
      <div v-if="!user.sage" class="cell large-6 medium-6 small-12">
        <md-field :class="getValidationClass('allowedFacilities')">
          <label>Zugewiesene Einrichtungen</label>
          <md-select v-model="user.allowedFacilities" autocomplete="off" id="allowed-facilities"
                     :disabled="sending || (!(isSage || isAdmin))" multiple>
            <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber" :key="facility.referenceNumber">
              {{facility.name}}
            </md-option>
          </md-select>
          <span class="md-error"
                v-if="!$v.user.allowedFacilities.required">Mindestens eine Einrichtung ist notwendig</span>
        </md-field>
      </div>
      <div class="cell large-6 medium-6 small-6">
        <label :class="checkBoxWarningClass(!user.dsgvoConfirmationObtained)">Einwilligung Datenverarbeitung</label>
        <md-switch class="md-primary" v-model="user.dsgvoConfirmationObtained">
          <p :class="booleanText(user.dsgvoConfirmationObtained)">{{booleanText(user.dsgvoConfirmationObtained)}}</p>
        </md-switch>
        <md-tooltip>
          <div v-if="!user.dsgvoConfirmationObtained">
            Die Einwilligung gemäß Datenschutz-Grundverordnung (Art. 6 DSGVO)<br>
            zur Verarbeitung dieser personenbezogenen Daten muss<br>
            seitens <b>{{user.firstName}} {{user.lastName}}</b> schriftlich gegeben sein.
          </div>
        </md-tooltip>
      </div>
      <div class="cell large-3 medium-3 small-3">
        <label class="small-label">Benutzer aktiv</label>
        <md-switch class="md-primary" v-model="user.active" :disabled="sending || (!(isSage || isAdmin))">
          <p>{{booleanText(user.active)}}</p>
        </md-switch>
      </div>
      <div v-if="isSage" class="cell large-3 medium-3 small-3">
        <label class="small-label">Beta-User</label>
        <md-switch class="md-primary" v-model="user.beta" :disabled="sending || !isSage">
          <p>{{booleanText(user.beta)}}</p>
        </md-switch>
      </div>
    </div>
</template>

<script>

  import {validationMixin} from 'vuelidate'
  import {
    required,
    minLength,
    email,
  } from 'vuelidate/lib/validators'
  import jQuery from "jquery";
  import Password from 'vue-password-strength-meter'

  export default {
    name: 'UserForm',
    mixins: [validationMixin],

    components: { Password },

    props: {
      user: {
        required: true
      },
      sending: {
        required: true
      },
      mode: {
        required: true,
      },
      availableProfiles: {
        required: true
      },
      availableCustomerNo: {
        required: true
      },
      availableCustomerNos: {
        required: true
      },
      availableFacilities: {
        required: true
      }
    },

    data: () => ({
      invalid: true,
      invalidPassword: false,
      passwordScore: null,
      passwordNewScore: null,
    }),

    validations: {
      user: {
        firstName: {
          required,
          minLength: minLength(3)
        },
        lastName: {
          required,
          minLength: minLength(3)
        },
        rolesProfile: {
          required,
        },
        email: {
          required,
          email,
        },
        initialpw: {
          minLength: minLength(8)
        },
        customerNo: {
          required,
          minLength: minLength(9)
        },
        allowedFacilities: {
        },
      },
    },

    methods: {

      showPasswordScore(score) {
        this.passwordScore = score;
      },

      showpasswordNewScore(score) {
        this.passwordNewScore = score;
      },

      onOpenOrganizationSelector() {
        setTimeout(() => jQuery('.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content').css('max-width', '500px'), 50);
      },

      getValidationClass(fieldName) {
        let field = this.$v.user[fieldName];

        if (field && fieldName === 'allowedFacilities' && this.mode !== 'create' && this.user.allowedFacilities.length <= 0) {
          return {
            'md-invalid': field.$dirty
          }
        }

        if (field && fieldName === 'initialpw' && this.mode !== 'update' && this.user.initialpw.length === 0) {
          return {
            'md-invalid': field.$dirty
          }
        }

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validateUser() {
        this.$v.$touch();

        this.invalid = this.$v.$invalid;
        if (this.mode !== 'update' && this.user.initialpw === '') {
          this.invalid = true;
          this.invalidPassword = true;
        }

        if (this.mode === 'update' && this.user.allowedFacilities.length <= 0 && this.isSage && this.invalid === false) {
          this.invalid = true;
        }

        if (this.user.allowedFacilities.length <= 0 && !this.isSage && this.invalid === false) {
          this.invalid = true;
        }
      },

      booleanText(value) {
        return value ? 'ja' : 'nein';
      },

      checkBoxWarningClass(warn) {
        return warn ? 'small-label cb-warn' : 'small-label';
      },
    },

    computed: {
      webAppSettings() {
        return this.$store.getters.webAppSettings
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isSage() {
        return this.$store.getters.sage
      },

      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style lang="scss" scoped>

  .ja {
    color: forestgreen;
    font-weight: bold;
    font-size: larger;
  }

  .nein {
    color: red;
    font-weight: bold;
    font-size: larger;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

  .cb-warn {
    color: red;
    font-weight: bold;
  }
</style>
