<template>
  <div>
    <md-dialog :md-click-outside-to-close="false" v-if="user" :md-active.sync="showDialog" @md-closed="closeDialog">

      <md-dialog-title>
        <form novalidate @submit.prevent="validateUser">
          <div class="grid-x grid-padding-x">
            <div v-if="mode === 'update'" class="cell large-5 medium-5">
              <i class="fi-pencil"></i>&nbsp;&nbsp;&nbsp;Benutzerdaten bearbeiten
            </div>
            <div v-else-if="mode === 'add'" class="cell large-5 medium-5">
              <i class="fi-torso"></i>&nbsp;&nbsp;&nbsp;Neuen Benutzer hinzufügen
            </div>
            <div v-else-if="mode === 'create'" class="cell large-5 medium-5">
              <i class="fi-torso"></i>&nbsp;&nbsp;&nbsp;Neuen Benutzer anlegen (Sage)
            </div>
            <div class="cell large-2 medium-2 small-12" style="margin-top: -5px;">
              <SessionTime></SessionTime>
            </div>
            <div class="cell large-5 medium-5 text-center">
              <button v-if="mode === 'update'" class="button success" type="submit" :disabled="sending">
                <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
              </button>
              <button v-else-if="mode === 'add'" class="button success" type="submit" :disabled="sending">
                <i class="fi-torso"></i>&nbsp;&nbsp;&nbsp;Benutzer jetzt hinzufügen
              </button>
              <button v-else-if="mode === 'create'" class="button success" type="submit" :disabled="sending">
                <i class="fi-torso"></i>&nbsp;&nbsp;&nbsp;Benutzer jetzt anlegen
              </button>
              <button class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button" :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
              </button>
            </div>
          </div>
        </form>
      </md-dialog-title>

      <md-dialog-content>
        <UserForm ref="userForm" :user="user"
                  :availableProfiles="availableProfiles"
                  :availableCustomerNo="availableCustomerNo"
                  :availableCustomerNos="availableCustomerNos"
                  :availableFacilities="availableFacilities"
                  :sending="sending"
                  :mode="mode"/>

        <md-progress-bar md-mode="indeterminate" v-if="sending"/>
      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>

  </div>

</template>

<script>
  import UserForm from '../forms/UserForm';
  import SessionTime from '../animations/SessionTime';

  import UserService from '../../services/UserService';
  import HttpErrorHandler from '../../services/HttpErrorHandler';

  import {validationMixin} from 'vuelidate';

  let base64 = require('base-64');

  export default {
    name: 'EditUserDialog',
    mixins: [validationMixin],

    components: {
      UserForm,
      SessionTime,
    },

    props: {
      user: {
        required: true
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      },
      availableFacilities: {
        required: true
      },
      availableCustomerNo: {
        required: true
      },
      availableCustomerNos: {
        required: true
      }
    },

    watch: {
      user() {
        if (this.isAdmin) {
          this.getProfiles();
        } else {
          this.$store.commit('availableProfiles', [this.user.rolesProfile]);
        }
      },
    },

    data: () => ({
      success: true,
      sending: false,
    }),

    methods: {

      getProfiles() {
        this.sending = true;
        UserService.getProfiles()
          .then(response => {
            this.$store.commit('availableProfiles', response.data);
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Liste aller Nutzerprofile');
            this.sending = false;
          })
      },

      updateUser() {
        this.sending = true;
        this.success = false;

        if(this.user.initialpw) {
          this.user.initialpw = base64.encode(this.user.initialpw);
        }

        UserService.updateUser(this.user)
          .then(response => {
            this.form = response.data;
            this.success = true;
            this.sending = false;
            this.$emit("updateUserSuccess");
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Änderungen für Benutzer ' + this.user.email);
            this.sending = false;
          })
      },

      addUser() {
        this.sending = true;
        this.success = false;

        if(this.user.initialpw) {
          this.user.initialpw = base64.encode(this.user.initialpw);
        }

        UserService.addUser(this.user)
          .then(response => {
            this.form = response.data;
            this.success = true;
            this.sending = false;
            this.$emit("updateUserSuccess");
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Hinzufügen des Benutzers ' + this.user.email);
            this.sending = false;
          })
      },

      createUser() {
        this.sending = true;
        this.success = false;

        if(this.user.initialpw) {
          this.user.initialpw = base64.encode(this.user.initialpw);
        }

        UserService.createUser(this.user)
          .then(response => {
            this.form = response.data;
            this.success = true;
            this.sending = false;
            this.$emit("updateUserSuccess");
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Anlegen des Benutzers ' + this.user.email);
            this.sending = false;
          })
      },

      validateUser() {
        this.$refs.userForm.validateUser();

        if (!this.$refs.userForm.invalid) {

          if (this.mode === 'update') {
            this.updateUser();
          }
          if (this.mode === 'add') {
            this.addUser();
          }
          if (this.mode === 'create') {
            this.createUser();
          }
        }
      },

      closeDialog() {
        this.$emit("closeEditUserDialog");
      }
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isSage() {
        return this.$store.getters.sage
      },

      isDarkMode() {
        return this.$store.getters.darkMode
      },

      organization() {
        return this.$store.getters.organization;
      },

      availableProfiles() {
        return this.$store.getters.availableProfiles;
      },
    },
  };
</script>

<style scoped>
  .md-dialog /deep/.md-dialog-container {
    min-width: 95%;
    min-height: 600px;
  }
</style>